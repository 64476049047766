import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackToWork from "../components/backtowork"

const InternalPage = () => (
  <Layout>
    <SEO title="Art Van Checkout" />
    <main className="internal">
      <BackToWork />
      <div className="content">
        <div className="full">
          <h1>Case Study: Art Van Checkout</h1>
          <p>Frequent drop-off rates and a complicated checkout experience prompted an investigation a full redesign. Throughout the redesign, the product team kept these key things in mind:</p>
          <ul>
            <li>What are some of the pain-points customers are running into while in the checkout journey (feedback taken from Voice of the Customer)?</li>
            <li>How can we solution for these pain-points? What are we hypothesising will happen if we design solutions for these issues?</li>
            <li>Scrap what we know about Checkout as it exists today, and design the ideal customer experience journey from the beginning of Checkout to Confirmation Of Purchase.</li>
          </ul>
          <h2>Final Wireframe</h2>
          <figure>
            <div className="video-wrapper">
              <iframe title="Prototype of shopping cart through delivery completion" src="https://player.cloudinary.com/embed/?cloud_name=innocent&public_id=hireallisonokeefe%2Fart-van-checkout%2Fcheckout-video_hppp52&fluid=true&controls=true&source_types%5B0%5D=mp4" allow="fullscreen; encrypted-media; picture-in-picture" allowFullScreen frameBorder="0" />
            </div>
            <figcaption>Above: final wireframe of Art Van Checkout, Happy Path scenario.</figcaption>
          </figure>
          <h2>Checkout as Guest vs. Sign Into an Account</h2>
          <p>Conventionally, guest checkout allows the customer to follow through the checkout process and create an account after reaching the confirmation page.</p>
          <p>Rather than scare away the customer and making them think Art Van wants to capture their information to spam them, gathering the customer email and phone number should be relocated to a more thoughtful location within the checkout. Instead, when a customer interacts with the Checkout CTA from within the Cart, prompt a drawer that asks customers clearly to either Continue As Guest or Sign In & Checkout.</p>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584663146/hireallisonokeefe/art-van-checkout/checkout_01_fwqnnl.png" alt="" />
            <figcaption>Above: highlighted section at top of checkout where customer information is captured.</figcaption>
          </figure>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584663148/hireallisonokeefe/art-van-checkout/checkout_02_xg0kve.png" alt="" />
            <figcaption>Above: proposed concept for Checkout as Guest or Sign Into an Account.</figcaption>
          </figure>
        </div>
        <div className="full">
          <h2>Multi-page experience vs. Single Page</h2>
          <p>The Art Van Checkout contains 3 pages, which in theory sounds like checking out won't take much time. However, each page requires customer to input information into many fields.</p>
          <ul>
            <li>Delivery: 11 input fields</li>
            <li>Billing/Credit Card: 15 input fields (assuming billing address is different from delivery address)</li>
            <li>Billing/Art Van Signature Card: 13 input fields (assuming billing address is different from delivery address)</li>
            <li>Review & Place Order: 0 input fields</li>
          </ul>
          <p>Overall: across Art Van Checkout, a customer will be inputting information into 25 different fields. </p>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584663148/hireallisonokeefe/art-van-checkout/checkout_03_ouiskg.png" alt="" />
            <figcaption>Above: multi-step checkout experience on artvan.com.</figcaption>
          </figure>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584663151/hireallisonokeefe/art-van-checkout/checkout_04_jthatg.jpg" alt="" />
            <figcaption>Above: an early sketch of single page checkout concept.</figcaption>
          </figure>
        </div>
        <div className="full">
          <p>Creating a single page experience where fields collapse after a customer has finished with each section gives the customer a feeling of completion and making progress.</p>
          <p>Chunking sections of the checkout also allows customers to focus on one task at a time, rather than being distracted by something that could pull them out of the funnel. </p>
          <h2>Delivery and In-Store Pickup</h2>
          <p>From the cart, if a customer makes the choice to have their items delivered, then this choice would persist to checkout. Simplifying how a customer makes changes to their delivery date and shipping options helps reduce customer confusion.</p>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584663148/hireallisonokeefe/art-van-checkout/checkout_05_ktehmc.png" alt="" />
            <figcaption>Above: Delivery/Pickup step within the checkout funnel.</figcaption>
          </figure>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584663149/hireallisonokeefe/art-van-checkout/checkout_06_ihaaud.png" alt="" />
            <figcaption>Above: proposed wireframe for Delivery/Pickup.</figcaption>
          </figure>
        </div>
        <div className="full">
          <h2>Delivery Address</h2>
          <p>In the spirit of information persisting from the cart, if a customer has input their ZIP code to estimate delivery options this would follow through to the step regarding Delivery Address. </p>
          <p>Because we know the customers ZIP code, we can auto fill the City and State to create a faster and more seamless experience. This also helps reduce the number of fields needing customer input from 9 to 4.</p>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584663150/hireallisonokeefe/art-van-checkout/checkout_07_calmrx.png" alt="" />
            <figcaption>Above: delivery address fields within checkout.</figcaption>
          </figure>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584663149/hireallisonokeefe/art-van-checkout/checkout_08_b1ptv3.png" alt="" />
            <figcaption>Above: proposed wireframe for delivery address fields.</figcaption>
          </figure>
        </div>
        <div className="full">
          <h2>Payment/Billing</h2>
          <p>Ideally, all payment done online should feel like it's being handled securely by the company a customer is trusting with their card information. Reinforcing this through certain language and utilizing basic security functionality will help customers feel their information is safe and will continue through the funnel.</p>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584663150/hireallisonokeefe/art-van-checkout/checkout_09_clnq96.png" alt="" />
            <figcaption>Above: how payment methods are displayed within checkout.</figcaption>
          </figure>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584663151/hireallisonokeefe/art-van-checkout/checkout_10_kgdfzf.png" alt="" />
            <figcaption>Above: proposed wireframe for section on Payment within the checkout.</figcaption>
          </figure>
        </div>
        <div className="full">
          <p>Utilizing a "Same as Shipping Address" field helps reduces the amount of repetitive customer information needed to follow through to Review.</p>
          <h2>Review</h2>
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584663151/hireallisonokeefe/art-van-checkout/checkout_11_yyji1c.png" alt="" />
            <figcaption>Above: proposed wireframe for final step within the checkout funnel.</figcaption>
          </figure>
          <h2>Expected Results</h2>
          <p>At it's current state, only 0.1% of customers are getting through the entire checkout funnel. By implementing these updates this number could potentially increase to 0.89%, the average percent in the furniture eCommerce market, leading to an increase in conversion and an overall increase in revenue.</p>
          <p><span className="text--bold">Programs used:</span> Sketch App</p>
        </div>
      </div>
    </main>
  </Layout>
)

export default InternalPage
